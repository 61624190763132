body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
  color: rgba(167, 167, 167, 0.87);
  /* font-family: Roboto, Helvetica, Arial, sans-serif; */
  margin: 0;
  padding: 0;
  /* overflow: hidden; */

  -moz-transform: scale(1.14); /*for Firefox, default 1*/
  zoom:114%; /* For Chrome, IE, default 100%*/
}

html {
  background: #000000 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 414px) {
  body {
    zoom: 100% !important;
    -moz-transform: scale(1) !important; /*for Firefox, default 1*/
  }
}

@media only screen and (max-width: 366px) {
  body {
    zoom: 80% !important;
    -moz-transform: scale(0.80) !important;
  }
}

@media only screen and (max-width: 297px) {
  body {
    zoom: 60% !important;
    -moz-transform: scale(0.60) !important;
  }
}

.navBar {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 18px;
}

.hoverText {
  text-shadow: 0 0 6px rgba(206, 205, 205, 0.904);
  color: rgb(206, 202, 202);
  transition: all 0.6s ease-in;
  transition: all 0.17s ease-out;
}

.specialImageHover {
  /* box-shadow: 0px 0px 0px 15px rgba(206, 205, 205, 0.904); */
  box-shadow: 0px 0px 32px 2px rgba(206, 205, 205, 0.904);
}

#websiteTitle {
  /* color: silver; */
  color: rgb(238, 238, 238);
  font-size: 30px;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.404);
  user-select: none;
}

#websiteTitle:hover {
  /* color: silver; */
  color: rgb(189, 189, 189);
  font-size: 30px;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.404);
  --d: 0%;
}

.aboutDisplay {
  padding: 76px 40px 0px 40px;
  line-height: 1.6;
  max-width: 70ch;
  margin: auto;
  /* text-align: center; */
  margin-bottom: 90px;
}

.aboutDisplay > p {
  color: white;
  margin: 1.3rem 0px;
}

.aboutAnchor {
  transition: 0.34s;
}

.aboutAnchor:hover {
  /* color: silver; */
  color: rgb(189, 189, 189);
  /* text-shadow: 0 0 15px rgba(255, 255, 255, 0.404); */
  --d: 0%;
  transition: 0.34s;
}

/* .portfolioDisplay {
  padding: 60px;
  padding-top: 80px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
} */

.portfolioDisplay {
  padding-top: 80px;
  display: grid;
  /* grid-template-columns: repeat(auto-fill,max(178px, 100%)); */
  grid-template-columns: fit-content(100%);
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;
}

@media only screen and (min-width: 1370px) {
  .portfolioDisplay {
    padding-top: 80px;
    display: grid;
    grid-template-columns: repeat(auto-fill,max(178px, 50%));
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.portfolioPiece {
  text-align: center;
  flex: 50%;
  /* width: 500px;
  height: 500px; */
  user-select: none;
}

#itemTitle {
  color: white;
}

.imageDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.webImageDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 38px;
}

.portfolioInner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
  padding-left: 65px;
  padding-right: 65px;
}

.portfolioInnerWeb {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 69px;
  padding-bottom: 60px;
  padding-left: 65px;
  padding-right: 65px;
}

.repoText:hover {
  transition: all 0.17s ease-in-out;
  color:rgba(255, 238, 4, 0.74); 
  text-shadow: 0 0 6px rgba(255, 238, 4, 0.719);
}

.repoText {
  color: inherit;
  transition: all 0.17s ease-in-out;
}

#describe {
  width: 400px;
}

#imgWeb {
  height: 222px;
  width: 402px;
  position: relative;
  top: -1px;
  right: -1px;
}

#imgTempo {
  height: 216px;
  width: 402px;
  position: relative;
  top: -1px;
  right: -1px;
}

#iPhone {
  z-index: 2;
  position: absolute;
  /* top: 127px;
  left: 265.1px; */
  margin-left: -0.8px;
  margin-top: -11px;
  height: 423px;
  width: 276px;
}

#macBook {
  z-index: 2;
  position: absolute;
  /* top: 127px; */
  /* left: 265.1px; */
  margin-left: 0.6px;
  margin-top: -22px;
  height: 271px;
  width: 525px;
}

.mobileItem {
  width: 177.820312px;
}

.webItem {
  width: 400px;
}

#imgMobile {
  /* height: 389.654297px; */
  height: 386.654297px;
  width: 177.820312px;
}

.imgNotSelected {
  user-select: none;
  box-shadow: 0 0 7px rgba(165, 165, 165, 0.226);
  border-radius: 2px;
  transition: box-shadow 0.14s ease;
}

/* .imgMobile {
  height: 222px;
  width: 400px;
  user-select: none;
  box-shadow: 0 0 7px rgba(165, 165, 165, 0.226);
  border-radius: 2px;
  transition: box-shadow 0.14s ease;
} */

/* img:hover {
  box-shadow: 0 0 15px rgba(206, 205, 205, 0.904);
} */

/* .navItem {
  text-decoration: none;
  margin-top: 8px;
  color: grey;
  user-select: none;
} */

.navItem {
  padding-bottom: 1.2px;
  margin-top: 8px;
  background: 
      linear-gradient(currentColor 0 0) 
      bottom /var(--d, 0) 1px 
      no-repeat;
  transition:0.34s;
  user-select: none;
}

.navItem:hover {
  --d: 100%;
}

.rightNavParent {
  padding-top: 10px;
}

.rightNavItem {
  margin-left: 19px;
}

@media only screen and (max-width: 696px) {
  /* .portfolioDisplay {
    padding: 0px;
    padding-top: 80px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
  } */

  #websiteTitle:hover {
  /* color: silver; */
  color: rgb(238, 238, 238);
  /* font-size: 30px;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.404);
  --d: 0%; */
  }

  .navItem:hover {
  --d: 0% !important;
  }

  .portfolioPiece {
    text-align: center;
    padding-bottom: 60px;
  }

  .mobileItem {
    width: 100%;
    max-width: 177.820312px;
  }

  .webItem {
    width: 100%;
    max-width: 400;
  }

  #imgWeb {
    /* width: 102%;
    min-width:220px;
    max-height: 128px;
    max-width: 400px;
    height: 128px !important; */
    width: 220px;
    height: 126px;
  }

  #imgTempo {
    /* width: 102%;
    min-width:220px;
    max-width: 220px !important;
    max-height: 126px;
    height: 126px !important; */
    width: 220px;
    height: 126px;
  }

  #macBook {
    z-index: 2;
    position: absolute;
    /* top: 127px; */
    /* left: 265.1px; */
    margin-left: 0.6px;
    margin-top: -11px;
    height: 158px;
    width: 526px;
    max-width: 290px;
  }

  #imgMobile {
    width: 100%;
    height: auto;
    max-height: 389.654297px;
    max-width: 177.820312px;
  }

  .aboutDisplay {
    padding: 60px;
    padding-top: 80px;
    padding-left: 54px;
    padding-right: 54px;
    line-height: 1.6;
    /* text-align: center; */
    margin-bottom: 0px;
  }
}

.aboutDisplay > p:last-child > p {
  display: inline;
  user-select: all;
}

p {
  margin-top: 6px;
  margin-bottom: 1px;
  /* user-select: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.descriptionText {
  max-width: 400px;
}

.activeNavLink {
  color: rgb(238, 238, 238);
  /* background: 
      linear-gradient(currentColor 0 0) 
      bottom /var(--d, 0) 1px 
      no-repeat; */
  /* transition:0; */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.445); */
  padding-bottom: 1.2px;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.281)
}
